import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const HomeAbout = () => {

    return (
        <section data-scrollax-parent="true" id="overview">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" > overview  <span>//</span></div>
                <div className="container">
                <div className="row">
                   
                    <div className="col-md-12">
                        <div className="main-about fl-wrap">
                            <h2> <span>Expediting </span>  Business Outcomes</h2>
                            <p>We coalesce Tier-1 Open Source eCommerce platforms, expertise, and experience to help your businesses succeed online.</p>
                         
                            <p>The platforms we work with bring on board Tier-1 eCommerce functionality, UI/UX experience, scalability, interoperability, and extensibility to integrate with 3rd party local/global platforms to offer your discerning customers a holistic shopping experience.</p>
                            <p>There is no foreseeable control or ending to the raging global pandemic. This is driving the need and realization for online fulfillment and convenience. As the COVID-19 pandemic reshapes our world, more consumers have begun shopping online in greater numbers and frequency.</p>
                            <p>PragICTS brings tier-1 IT expertise and experience so that you can focus on your business. We do the required due diligence to design, implement, manage and support.</p>
                            <p>According to new data from IBM’s U.S. Retail Index, the pandemic has accelerated the shift away from physical stores to digital shopping by roughly five years.</p>
                            <p>For fuller details refer to our</p>
                            <a href="https://pragicts.blogspot.com/2020/10/the-context-for-ecommerce.html" target="" class="btn float-btn flat-btn color-btn">Blog</a>

                            <div class="features-box-container fl-wrap"><div class="row"><div class="features-box col-md-4"><div class="time-line-icon"><i class="fal pragicts-ecommerce-1-icon"></i></div><h3>Global eCommerce to Reach 8.1 Trillion USD by 2026</h3></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fal pragicts-global-network-icon"></i></div><h3>As of 2023, the number of digital buyers is at 2.64 billion. This makes up 33.3% of the population worldwide.</h3></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fab pragicts-24x7_1-icon"></i></div><h3>The number #1 reason people shop online is that they’re able to shop at all hours of the day.</h3></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fal pragicts-internet-search-icon"></i></div><h3>81% of consumers conduct online research before making a purchase online.</h3></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fab pragicts-family-icon"></i></div><h3>81% of consumers trust the advice of friends and family over businesses.</h3></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fal pragicts-price-comparison-icon"></i></div><h3>65% of shoppers look up price comparisons on their mobile device while in a physical store.</h3></div></div></div>
                       {/*      <div className="features-box-container fl-wrap">
                                <div className="row">
                                    <div className="features-box col-md-6">
                                        <div className="time-line-icon">
                                            <i className="fal pragicts-software-icon"></i>
                                        </div>
                                        <Link to="/service/software-development"><h3>Software</h3></Link>
                                        <p>Software development and support services. Web (including websites), Mobile and Rich Client Applications.</p>
                                        <p><Link to="/service/software-development">More Details ...</Link></p>
                                    </div>
                                    <div className="features-box col-md-6">
                                        <div className="time-line-icon">
                                            <i className="fal pragicts-infrastructure-icon"></i>
                                        </div>
                                        <a target="_blank" href="https://infrastructure.pragicts.com"><h3>Infrastructure</h3></a>
                                        <p>Implementation, management, and support of ICT Infrastructure.  On-site, remote, hybrid, and on-call service models.</p>
                                        <p><a target="_blank" href="https://infrastructure.pragicts.com">More Details ...</a></p>
                                    </div>
                                    <div className="features-box col-md-6">
                                        <div className="time-line-icon">
                                            <i className="fab pragicts-cloud-icon"></i>
                                        </div>
                                        <a target="_blank" href="https://cloud.pragicts.com"><h3>Cloud</h3></a>
                                        <p>Provisioning, management, and support of cloud platforms. Amazon, Microsoft, Google, and other third parties.</p>
                                        <p><a target="_blank" href="https://cloud.pragicts.com">More Details ...</a></p>
                                    </div>
                                </div>
                            </div>  */}
                            {/* <a href="portfolio.html" className="btn float-btn flat-btn color-btn">My Portfolio</a> */}
                        </div>
                    </div>
                </div>
            </div>
           {/*     <div className="container">
                <div className="row">
                    <div className="col-md-8">

                    <div className="col-md-4">  <div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="2" data-height="45" data-nofollow="true" data-expandifr="true" data-scale="100" data-clutchcompany-id="1504388"></div></div>
                    <div className="col-md-4">    <a href="https://www.designrush.com/agency/profile/pragicts" target="_blank"><StaticImage src="../images/home/designrush-accredited-agency-pragicts.png" className="highlights" ></StaticImage></a></div>

                    </div>
                </div>
            </div>*/}
            <div className="bg-parallax-module" data-position-top="90"  data-position-left="25" data-scrollax="properties: { translateY: '-250px' }"></div>
            <div className="bg-parallax-module" data-position-top="70"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
            <div className="sec-lines"></div>
        </section>
    )
}

export default HomeAbout
