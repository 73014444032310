import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HomeSolutions = () => {
    return (
        <section data-scrollax-parent="true" id="highlights">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>highlights </div>
            <div className="container">
                <div className="section-title fl-wrap">
                    <h3>OUR HIGHLIGHTS</h3>
                    <h2>FEATURED <span> HIGHLIGHTS</span></h2>
          
                </div>
                <div class="process-wrap fl-wrap"><ul><li><div class="time-line-icon"><i class="fab pragicts-analysis-icon"></i></div><h4>Proven Platforms</h4><div class="process-details"><p>300,000+ Global deployments.</p></div><span class="process-numder">01.</span></li><li><div class="time-line-icon"><i class="fal pragicts-requirements-articulation-icon"></i></div><h4>100% Customizable</h4><div class="process-details"><p>Tailored to your exact needs</p>                            </div><span class="process-numder">02.</span></li><li><div class="time-line-icon"><i class="fal pragicts-design-icon"></i></div><h4>100% Responsive</h4><div class="process-details"><p>Operate from any device</p></div><span class="process-numder">03.</span></li><li><div class="time-line-icon"><i class="fal pragicts-support-icon"></i></div><h4>100% Open Source</h4><div class="process-details"><p>Platform neutral (on-premise/cloud)</p></div><span class="process-numder">04.</span></li><li><div class="time-line-icon"><i class="fal pragicts-design-1-icon"></i></div><h4>Seamless Integration</h4><div class="process-details"><p>Integrate with any payment/logistic provider</p></div><span class="process-numder">05.</span></li><li><div class="time-line-icon"><i class="fal pragicts-design-2-icon"></i></div><h4>Live Dashboard</h4><div class="process-details"><p>Real-time oversight &amp; management</p></div><span class="process-numder">06.</span></li></ul></div>
            </div>
           
            <div className="sec-lines"></div>
        </section>
    )
}

export default HomeSolutions
