import React from 'react'

import { Link } from "gatsby"


import toolshub from '../images/featuredprojects/toolshub.png'
import biophilia from '../images/featuredprojects/biophilia.png'
import concept from '../images/featuredprojects/concept.png'


const HomeStatsecom = () => {

    return (
        <section className="dark-bg no-padding" id="services">
             <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>Featured Projects</div>
            <div >
                <div className="section-title fl-wrap">
                   
                    <h2>Featured <span> Projects</span></h2>
          
                </div>
        <div className="fs-carousel-wrap fl-wrap full-height slider-carousel-wrap">
            <div className="fs-carousel fl-wrap full-height cur_carousel-slider-container">
                <div style={{ paddingTop: '10px', paddingBottom: '10px'}} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3>TOOLSHUB</h3>
               
                    </div>
                    <div className="carousle-item-number"><span>01.</span></div>
                    <div className="bg"  data-bg={toolshub}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                    <h3>BIOPHILIA</h3>
                    </div>
                    <div className="carousle-item-number"><span>02.</span></div>
                    <div className="bg"  data-bg={biophilia}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                    <h3>THE CONCEPT STORE</h3>
                    </div>
                    <div className="carousle-item-number"><span>03.</span></div>
                    <div className="bg"  data-bg={concept}></div>
                    <div className="overlay"></div>
                </div>
               
            </div>
            <div className="sp-cont sarr-contr sp-cont-prev"><i className="fal fa-arrow-left"></i></div>
            <div className="sp-cont sarr-contr sp-cont-next"><i className="fal fa-arrow-right"></i></div>
            <div className="slider-nav-counter"></div>
        </div>
        </div>
    </section>

    )
}

export default HomeStatsecom
