import React from "react"
import HeroSlides from "../components/hero-slides"
import Layout from "../components/layout"
import HomeSolutions from "../components/home-solutions"
import HomeAbout from "../components/home-about"
import Footer from "../components/footer"
import Homepayment from "../components/home-payment"
import Homestats from "../components/home-stats"


import Technologies from "../components/technologies"

import SocialIconsFooter from "../components/social-icons-footer"







const IndexPage = () => {
    return (
        <Layout
            pageTitle="PragICTS | eCommerce"
        >
            <div id="wrapper">
                <div className="scroll-nav-wrap fl-wrap">
                    <div className="scroll-down-wrap">
                        <div className="mousey">
                            <div className="scroller"></div>
                        </div>
                        <span>Scroll Down</span>
                    </div>
                    <nav className="scroll-nav scroll-init">
                        <ul>
                            {/* <li><a className="scroll-link act-link" href="#sec1">Hero</a></li> */}
                            <li><a className="scroll-link" href="#overview">Overview</a></li>
                            <li><a className="scroll-link" href="#highlights">highlights</a></li>
                            <li><a className="scroll-link" href="#featuredprojects">Featured Projects</a></li>
                            <li><a className="scroll-link" href="#payment_provides">Payment provides</a></li>
                            <li><a className="scroll-link" href="#technologies">technologies</a></li>
                        </ul>
                    </nav>
                </div>

                <HeroSlides />

                <div className="content">

                    {/* overview section */}
                    <HomeAbout />

                    {/* solutions  */}
                  <HomeSolutions />

                    {/* numbers section */}
                  
                  {/* <HomeCdn /> */}
                  <Homestats />
  
                  
                    {/* <HomeCdn /> */}
                    <Homepayment />

       

                        {/* client testimonials */}
                        <Technologies />

                    {/* video presentation */}
                    {/* <HomeVideo /> */}

          

                


                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default IndexPage
