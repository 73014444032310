import React from 'react'
import xcart from '../images/technologies/xcart-logo.png'
import zencart from '../images/technologies/zencart-logo.png'
import opencart from '../images/technologies/opencart-logo.png'
import prestashop from '../images/technologies/prestashop-logo.png'
import magento from '../images/technologies/magento-logo.png'


const clients = [
    {
        name: 'xcart',
        logo: xcart,
        url: 'https://www.x-cart.com/'
    },
    {
        name: 'Zencart',
        logo: zencart,
        url: 'https://www.zen-cart.com/'
    },
    {
        name: 'Opencart',
        logo: opencart,
        url: 'https://www.opencart.com/'
    },
    {
        name: 'Prestashop',
        logo: prestashop,
        url: 'https://prestashop.com/'
    },
    {
        name: 'Magento',
        logo: magento,
        url: 'https://business.adobe.com/products/magento/magento-commerce.html'
    }
   
]

const Technologies = () => {
    return (
        <section  data-scrollax-parent="true" id="technologies">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" >Testimonials<span>//</span></div>
            <div className="container" >
                <div className="section-title fl-wrap">
                    <h3>Technology Stack</h3>
                    <h2>Support <span> Platforms</span></h2>
                 
                </div>
            </div>
        
         
            <div className="fl-wrap">
                <div className="container">
                    <ul className="client-list client-list-white">
                        {clients.map(client => (
                            <li key={client.name}><a href={client.url} target="_blank"><img src={client.logo} className='respimg' alt={client.name} title={client.name} /></a></li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="sec-lines"></div>
        </section>
    )
}

export default Technologies
